<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.6402 21.2682C16.2694 21.5773 15.7307 21.5773 15.3598 21.2682L6.35984 13.7682C5.93556 13.4147 5.87824 12.7841 6.2318 12.3598C6.58537 11.9356 7.21593 11.8782 7.64021 12.2318L16 19.1983L24.3598 12.2318C24.7841 11.8782 25.4147 11.9356 25.7682 12.3598C26.1218 12.7841 26.0645 13.4147 25.6402 13.7682L16.6402 21.2682Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconChevronDown",
});
</script>
